import React, {useState, useEffect} from 'react';
import '../assetss/css/Inicio.css'
import '../assetss/css/App.css'
import { connect } from 'react-redux';
import { setSaldosEmpresa } from '../actions'
import { get_saldos_empresa } from "../services/Login"
import { get_graficos } from "../services/Reportes"
import { AgChartsReact } from 'ag-charts-react';
import { toast, formatearMonedaValue } from "../services/utilities";
import HabilitarSaldo from './Inicio/HabilitarSaldo';
import TrasladoSaldo from './Inicio/TrasladoSaldo';
import RetirarSaldo from './Inicio/RetirarSaldo';
import RetirarSaldoSocio from './Inicio/RetirarSaldoSocio';
//doc //doc https://www.ag-grid.com/charts/gallery/

function Inicio(props) {

    useEffect(() => {
        handleConsultarSaldosEmpresa()
        handleGetGraficos();
    }, []);

    const [spiner, setSpiner] = useState(false);
    const [creditos_ultimo_ano, setCreditosUltimoAno] = useState([]);
    const [utilidad_capital_por_mes, setUtilidad] = useState([]);

    const handleGetGraficos = async () => {

      setSpiner(true)

      try {
          await get_graficos().then(response => {
              if(response.status === 200){
                setCreditosUltimoAno(response.data.creditos_ultimo_ano);
                const utilidadPorMesNumerica = response.data.utilidad_capital_por_mes.map(item => ({
                  ...item,
                  utilidad: item.utilidad !== null ? Number(item.utilidad) : null,
                  capital: item.capital !== null ? Number(item.capital) : null
                }));
                setUtilidad(utilidadPorMesNumerica);
              }
          });
      }catch (error) {
          const errorMessage = error.message || 'Error desconocido';
          toast('Error al cargar la información de graficos', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`,true, 'error');
      }

      setSpiner(false)

    }

    const ChartCreditosRealizados = () => {
      // Chart Options: Control & configure the chart
      const [chartOptions, setChartOptions] = useState({
        // Data: Data to be displayed in the chart
        data: creditos_ultimo_ano,
        background: {
          fill: '#ffffff',
        },
        series: [{ type: 'line',  xKey: 'mes_abreviado', yKey: 'total_creditos', yName: 'mes' }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: { text: 'Último año' }
          },
          {
            type: 'number',
            position: 'left',
            title: { text: 'Creditos realizados' }
          }
        ]
      });
    
      return (
        // AgChartsReact component with options passed as prop
        <AgChartsReact options={chartOptions} />
      );
    }

    const ChartUtilidadPorMes = () => {
      // Chart Options: Control & configure the chart
      const [chartOptions, setChartOptions] = useState({
        // Data: Data to be displayed in the chart
        data: utilidad_capital_por_mes,
        background: {
          fill: '#ffffff',
        },
        series: [{ type: 'bar',  xKey: 'mes_abreviado', yKey: 'utilidad', yName: 'mes', fill: '#198754', }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: { text: 'Últimos 6 meses' }
          },
          {
            type: 'number',
            position: 'left',
            title: { text: 'Utilidad recaudada por mes' }
          }
        ]
      });
    
      return (
        // AgChartsReact component with options passed as prop
        <AgChartsReact options={chartOptions} />
      );
    }

    const ChartCapitalPorMes = () => {
      // Chart Options: Control & configure the chart
      const [chartOptions, setChartOptions] = useState({
        // Data: Data to be displayed in the chart
        data: utilidad_capital_por_mes,
        background: {
          fill: '#ffffff',
        },
        series: [{ type: 'bar',  xKey: 'mes_abreviado', yKey: 'capital', yName: 'mes' }],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: { text: 'Últimos 6 meses' }
          },
          {
            type: 'number',
            position: 'left',
            title: { text: 'Capital recaudado por mes' }
          }
        ]
      });
    
      return (
        // AgChartsReact component with options passed as prop
        <AgChartsReact options={chartOptions} />
      );
    }

    const handleConsultarSaldosEmpresa = async () => {

      get_saldos_empresa().then(response => {
          if(response.status === 200){
              props.setSaldosEmpresa(response.data.data)
          }else{
              toast('Error al cargar los saldos', response.message)
          }
      }).catch(error =>{
          toast('Error al cargar los saldos', error.message) 
      }). finally(
          
      )
    }
      
    return (
        <React.Fragment>         
            <div className='container-inicio-header'>
              <div className='row row-saldos-inicio'>
                <div className='col-md-3 text-center col-saldo-inicio'>
                    <h5 className='text-secondary m-0'><i className="bi bi-arrow-up-circle"></i> Capital</h5>
                    <h2 className='m-0 text-success'><b>${formatearMonedaValue(props.saldosEmpresa.disponible)}</b></h2>
                    <span style={{fontSize:'12px'}} className='text-secondary'><i className="bi bi-arrow-bar-up mx-1 text-success"></i><b>Saldo disponible para prestamos</b></span>
                </div>
                <div className='col-md-3 text-center col-saldo-inicio'>
                  <h5 className='text-secondary m-0'><i className="bi bi-arrow-down-circle"></i> Prestado</h5>
                  <h2 className='m-0 text-secondary'><b>${formatearMonedaValue(props.saldosEmpresa.prestado)}</b></h2>
                  <span style={{fontSize:'12px'}} className='text-secondary'><i className="bi bi-clipboard-check mx-1 text-success"></i><b>{props.saldosEmpresa.cantidad_creditos} creditos activos</b></span>
                </div>
                <div className='col-md-3 text-center col-saldo-inicio'>
                  <h5 className='text-secondary m-0'><i className="bi bi-clipboard-data"></i> Utilidad</h5>
                  <h2 className='m-0 text-primary'><b>${formatearMonedaValue(props.saldosEmpresa.intereses)}</b></h2>
                  <span style={{fontSize:'12px'}} className='text-secondary'><i className="bi bi-piggy-bank mx-1 text-success"></i><b>Saldo recaudado a la fecha</b></span>
                </div>
                <div className='col-md-3 text-center'>
                  <h5 className='text-secondary m-0'><i className="bi bi-clipboard2-x"></i>Creditos en mora</h5>
                  <h2 className='m-0 text-danger'><b>{props.saldosEmpresa.cantidad_creditos_mora}</b></h2>
                </div>
              </div>
              <div className='container-inicio-body'>
                <div className='row'>
                  <div className={props.usuario.perfil_id != 4 ? 'col-md-9':'col-md-12'}>
                    <div className='row'>
                      <div className='col-md-12'>
                        <div className="card">
                          <div className="card-body">                            
                            <div style={{width:"100%", height:"200px"}}>
                              <ChartCreditosRealizados />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mt-2'>
                      <div className='col-md-6'>
                        <div className="card">
                          <div className="card-body">                            
                            <div style={{width:"100%", height:"300px"}}>
                              <ChartUtilidadPorMes />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-md-6'>
                        <div className="card">
                          <div className="card-body">                            
                            <div style={{width:"100%", height:"300px"}}>
                              <ChartCapitalPorMes />
                            </div>
                          </div>
                        </div>
                      </div>                    
                    </div>
                  </div>
                  
                  {props.usuario.perfil_id != 4?
                    <div className='col-md-3'>
                      <h5 className="card-title">Administración de saldos</h5>
                      <hr></hr>
                      <div className="card mb-3 mt-0">
                        
                        <div className="card-body">
                          <div className='row'>
                            <div className='col-md-12'>
                                <div className="alert alert-success card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalHabilitarSaldo">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-coin" style={{fontSize: '20px'}}></i>
                                        <h6 className='my-0 mx-2'>Habilitar saldo disponible</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="alert alert-primary card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalTrasladoSaldo">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-repeat" style={{fontSize: '20px'}}></i>
                                        <h6 className='my-0 mx-2'>Trasladar saldo desde intereses</h6>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="alert alert-danger card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalRetirarSaldo">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-building-down" style={{fontSize: '20px'}}></i>
                                        <h6 className='my-0 mx-2'>Retirar saldo</h6>
                                    </div>
                                </div>
                            </div>
                            {props.usuario.credito_sociedad ?
                            <div className='col-md-12'>
                                <div className="alert alert-warning card-option" role="alert" data-bs-toggle="modal" data-bs-target="#ModalRetirarSaldoSocio">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-person-standing" style={{fontSize: '20px'}}></i>
                                        <h6 className='my-0 mx-2'>Retirar saldo - Socios</h6>
                                    </div>
                                </div>
                            </div>
                            :''}
                          </div>
                        </div>
                      </div>
                    </div>
                  :''}

                </div>
              </div>
            </div>
            <HabilitarSaldo></HabilitarSaldo>
            <TrasladoSaldo></TrasladoSaldo>
            <RetirarSaldo></RetirarSaldo>
            <RetirarSaldoSocio></RetirarSaldoSocio>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

  return {
      saldosEmpresa: state.saldosEmpresa,
      usuario: state.usuario
  }
}

const mapDispatchToProps  = {
  setSaldosEmpresa,
};

export default connect(mapSateToProps, mapDispatchToProps)(Inicio)