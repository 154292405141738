import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get_credito } from "../../services/Credito"
import { formatearMonedaValue } from "../../services/utilities"
import ModalAnularCredito from '../Creditos/ModalAnularCredito';

function Credito(props) {

    useEffect(() => {
        setSpiner(true);
        handleConsultarCredito()
    }, [props.idCredito]);

    const [spiner, setSpiner] = useState(false);
    const [hayData, setHayData] = useState(false);
    const [noFoundCredito, setNoFoundCredito] = useState(false);
    const [credito, setCredito] = useState({});

    const handleConsultarCredito = async () => {

        try {
            await get_credito(props.idCredito).then(response => {

                if(response.status === 200 && response.data.status_message == 'ok'){
                    setHayData(true);
                    setCredito(response.data.data);
                }else{
                    setNoFoundCredito(true)
                }
            });
         }catch (error) {
            const errorMessage = error.message || 'Error desconocido';
            //setMsg('Error al cargar la información del crédito', `Por favor intente nuevamente. Detalles del error: ${errorMessage}`);
        }

        setSpiner(false);
    }

    return(

        <React.Fragment>
            <div className='container-creditos' style={{fontSize:'12px'}}>

                {spiner ?
                <div className="d-flex align-items-center">
                    <strong role="status" className='text-secondary'>Cargando Información...</strong>
                    <div className="spinner-border ms-auto text-secondary" aria-hidden="true"></div>
                </div>:''}
                
                {hayData ?
                <>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <div className="card mb-3 mt-0">
                                        <div className="card-body">
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5 className="card-title m-0"><span className='mx-2'><i className="bi bi-clipboard-check"></i></span>Crédito</h5>
                                                <h2 className='text-primary m-0'>{credito.credito.id_credito}</h2>
                                            </div>
                                            <hr></hr>

                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Tipo</h6>
                                                <p className='m-0'>{credito.credito.tipo_credito}</p>
                                            </div>                                
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Valor</h6>
                                                <h6 className='m-0'><b>${formatearMonedaValue(credito.credito.valor)}</b></h6>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Plazo</h6>
                                                <p className='m-0'>{credito.credito.cantidad_cuotas} Meses</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Interes</h6>
                                                <p className='m-0'>{credito.credito.interes}%</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Fecha inicio</h6>
                                                <p className='m-0'><i className="bi bi-calendar3"></i> {credito.credito.fecha_inicio}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Día de pago</h6>
                                                <p className='m-0'><b>{credito.credito.dia_de_pago}</b></p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Cuota mensual</h6>
                                                <h6 className='m-0 text-secondary'><b>${formatearMonedaValue(credito.credito.cuota_mensual)}</b></h6>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Utilidad del credito</h6>
                                                <p className='m-0 text-success'><i className="bi bi-clipboard-data"></i> <b>${formatearMonedaValue(credito.credito.utilidad)}</b></p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Pago de capital</h6>
                                                <p className='m-0'><b>${formatearMonedaValue(credito.credito.capital_pagado)}</b></p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Saldo actual</h6>
                                                <h6 className='m-0 text-danger'><b>${formatearMonedaValue(credito.credito.saldo_actual)}</b></h6>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Último pago</h6>
                                                <p className='m-0'><i className="bi bi-calendar3"></i> {credito.credito.fecha_ultimo_pago}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Estado</h6>
                                                {credito.credito.estado_id === 4 ?
                                                    <span className="badge text-bg-success">{credito.credito.estado}</span>
                                                    : credito.credito.estado_id === 3 || credito.credito.estado_id === 13 ?
                                                    <span className="badge text-bg-danger">{credito.credito.estado}</span>
                                                    :
                                                    <span className="badge text-bg-warning">{credito.credito.estado}</span>
                                                }
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Dias mora</h6>
                                                <p className='m-0'>{credito.credito.dias_mora}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Fecha aprox de finalización</h6>
                                                <p className='m-0'><i className="bi bi-calendar3"></i> {credito.credito.fecha_fin}</p>
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                                
                                {credito.credito.tipo_sociedad === 'PROPORCIONAL' || credito.credito.tipo_sociedad === 'UTILIDAD'?
                                    <div className='col-md-12'>
                                        <div className="card mb-3 mt-0">
                                            <div className="card-body">
                                                
                                            <div className='d-flex align-items-center justify-content-between'>
                                                    <h5 className="card-title m-0"><span className='mx-2'><i className="bi bi-person-standing"></i></span>Sociedad</h5>
                                                    <h2 className='text-success m-0'>{credito.credito.socio}</h2>
                                                </div>
                                                <hr></hr>
                                                <div className='d-flex align-items-center justify-content-between mb-1'>
                                                    <h6 className="card-subtitle text-body-secondary">Tipo de sociedad</h6>
                                                    <h6 className='m-0'><b>{credito.credito.tipo_sociedad}</b></h6>
                                                </div>

                                                { credito.credito.tipo_sociedad === 'PROPORCIONAL' ? 
                                                    <>                                        
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-danger">% de inversion socio</h6>
                                                            <h6 className='m-0 text-danger'><b>%{formatearMonedaValue(credito.credito.inversion_socio)}</b></h6>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-danger">Capital de inversion socio</h6>
                                                            <h6 className='m-0 text-danger'><b>${formatearMonedaValue(credito.credito.capital_socio)}</b></h6>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-primary">% de inversion empresa</h6>
                                                            <h6 className='m-0 text-primary'><b>%{formatearMonedaValue(credito.credito.inversion_empresa)}</b></h6>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-primary">Capital de inversion empresa</h6>
                                                            <h6 className='m-0 text-primary'><b>${formatearMonedaValue(credito.credito.saldo_actual - credito.credito.capital_socio)}</b></h6>
                                                        </div>
                                                    </>
                                                :
                                                    <>                                        
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-primary">Beneficiario de utilidad</h6>
                                                            <h6 className='m-0 text-primary'><b>{credito.credito.beneficiario_utilidad}</b></h6>
                                                        </div>
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-primary">Porcentaje de beneficio</h6>
                                                            <h6 className='m-0 text-primary'><b>{formatearMonedaValue(credito.credito.utilidad_socio)}%</b></h6>
                                                        </div>    
                                                        <div className='d-flex align-items-center justify-content-between mb-1'>
                                                            <h6 className="card-subtitle text-dark">Capital invertido</h6>
                                                            <h6 className='m-0 text-dark'><b>${formatearMonedaValue(credito.credito.capital_socio)}</b></h6>
                                                        </div>                                                    
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                :''}

                                <div className='col-md-12'>
                                    <div className="card mb-3 mt-0">
                                        <div className="card-body">
                                            
                                        <div className='d-flex align-items-center justify-content-between'>
                                                <h5 className="card-title m-0"><span className='mx-2'><i className="bi bi-person-lines-fill"></i></span>Cliente</h5>
                                                <h2 className='text-success m-0'>{credito.cliente.nombre}</h2>
                                            </div>
                                            <hr></hr>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Codigo</h6>
                                                <p className='m-0'>{credito.cliente.id_cliente} - {credito.cliente.numero_documento}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Nombre</h6>
                                                <p className='m-0'>{credito.cliente.nombre} {credito.cliente.apellido}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between mb-1'>
                                                <h6 className="card-subtitle text-body-secondary">Telefono</h6>
                                                <p className='m-0'>{credito.cliente.telefono_1} - {credito.cliente.telefono_2}</p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between m-0'>
                                                <h6 className="card-subtitle text-body-secondary">Correo</h6>
                                                <p className='m-0'>{credito.cliente.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="card mb-3 mt-0">
                                        <div className="card-body">
                                            <h5 className="card-title"><span className='mx-2'><i className="bi bi-clipboard-plus"></i></span>Garantias del crédito</h5>
                                            <hr></hr>
                                            <div className='d-flex align-items-center justify-content-between  m-0'>
                                                <h6 className="card-subtitle mb-2 text-body-secondary">Vehículos</h6>
                                                <p className='m-0'></p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  m-0'>
                                                <h6 className="card-subtitle mb-2 text-body-secondary">Propiedad</h6>
                                                <p className='m-0'></p>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between  m-0'>
                                                <h6 className="card-subtitle mb-2 text-body-secondary">Letra/Contrato</h6>
                                                <p className='m-0'></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 mb-4'>
                                    <button 
                                        type="button" 
                                        className="btn btn-danger btn-md " 
                                        data-bs-target="#modalAnularCredito" 
                                        data-bs-toggle="modal"
                                        disabled={credito.credito.estado_id === 4 || credito.credito.estado_id === 13}
                                    >
                                        Anular credito
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className="card mb-3 mt-0">
                                <div className="card-body">
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <h5 className="card-title m-0"><span className='mx-2'><i className="bi bi-card-list"></i></span>Movimientos</h5>
                                        <button 
                                            type="button" 
                                            className="btn btn-primary btn-md " 
                                            data-bs-target="#modalRealizarPago" 
                                            data-bs-toggle="modal"
                                            disabled={credito.credito.estado_id === 4 || credito.credito.estado_id === 13}
                                        >
                                            Registrar un pago
                                        </button>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <div className="table-responsive">
                                            <table className="table table-hover table-sm text-center" style={{fontSize:'12px'}}>
                                                <thead>
                                                    <tr>
                                                        <th className='text-start'>Transacción</th>
                                                        <th>Fecha</th>
                                                        <th>Fecha Pago</th>
                                                        <th className='text-start'>Valor</th>
                                                        <th className='text-start'>Interes</th>
                                                        <th className='text-start'>Capital</th>
                                                        <th className='text-start'>Saldo</th>
                                                        <th>Estado</th>
                                                        <th>Usuario</th>
                                                        <th>Banco</th>
                                                        <th>Cuenta</th>
                                                        <th>Observación</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {Array.isArray(credito.movimientos) ? credito.movimientos.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='text-primary text-start'>
                                                            <b>{item.id_transaccion} - {item.tipo_transaccion}</b>
                                                        </td>
                                                        <td>
                                                            <span className="badge text-bg-dark">{item.fecha_registro}</span>
                                                        </td>
                                                        <td>
                                                            <span className="badge text-bg-primary">{item.fecha_pago}</span>
                                                        </td>
                                                        <td className='text-start'>
                                                            <b>${formatearMonedaValue(item.valor)}</b>
                                                        </td>
                                                        <td className='text-success text-start'>
                                                            <b>${formatearMonedaValue(item.interes)}</b>
                                                        </td>
                                                        <td className='text-secondary text-start'>
                                                            <b>${formatearMonedaValue(item.capital)}</b>
                                                        </td>
                                                        <td className='text-danger text-start'>
                                                            <b>${formatearMonedaValue(item.saldo_credito)}</b>
                                                        </td>
                                                        <td>
                                                        {item.estado_id === 4 || item.estado_id === 11 || item.estado_id === 12 || item.estado_id === 2 ?
                                                            <span className="badge text-bg-success">{item.estado}</span>
                                                            : item.estado_id === 3 || item.estado_id === 13 ?
                                                            <span className="badge text-bg-danger">{item.estado}</span>
                                                            :
                                                            <span className="badge text-bg-warning">{item.estado}</span>
                                                        }
                                                        </td>
                                                        <td>
                                                            {item.usuario}
                                                        </td>
                                                        <td>
                                                            {item.banco}
                                                        </td>
                                                        <td>
                                                            {item.cuenta_ingreso}
                                                        </td>
                                                        <td>
                                                            {item.observacion}
                                                        </td>
                                                    </tr>
                                                )) : <tr></tr>}
                                                </tbody>
                                            </table>
                                            { Array.isArray(credito.movimientos) && credito.movimientos.length == 0 ?
                                                <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary" style={{height: '30dvh'}}>
                                                    <i className="bi bi-card-checklist fs-1"></i>
                                                    <br></br>
                                                    <h3>No se encontraron movimientos para el código de crédito <b>{props.idCredito}</b></h3>
                                                </div>
                                            :''}
                                        </div>  
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :''}

            {noFoundCredito?
            <div className="d-flex flex-column justify-content-center align-items-center text-center text-secondary my-4" style={{height: '30dvh'}}>
                <i className="bi bi-search fs-1"></i>
                <br></br>
                <h3>No se encontró el código de crédito <b>{props.idCredito}</b></h3>
                <br></br>
                <p className='text-success'><b>Por favor, recargue la pagina e intente nuevamente.</b></p>
            </div>:''}

            </div>
            <ModalAnularCredito></ModalAnularCredito>
        </React.Fragment>
    );

}

const mapSateToProps = state => {

    return {
        idCredito: state.idCredito
    }
}

export default connect(mapSateToProps, null)(Credito)