import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { setSaldosEmpresa } from '../../actions'
import { traslado_saldo_intereses } from "../../services/Ajustes"
import { alerta, toast, cerrarModal } from "../../services/utilities";
import Swal  from 'sweetalert2';
import { editar_empresa, getEmpresa } from '../../services/Root';
import Sidebar from './Sidebar';
import ListaEmpresas from './ListaEmpresas';


function EditarEmpresa(props){

    useEffect(() => {
        handleGetEmpresa(props.idEmpresa);
    },[props.idEmpresa]);

    const [spiner, setSpiner] = useState(true);
    const [empresa, setEmpresa] = useState({
            "id_empresa":0,
            "nombre":"",
            "nombre_contacto":"",
            "telefono_contacto":"",
            "correo":"",
            "dominio":"https://www.tecnofinan.site/",
            "segunda_clave":"",
            "password":"",
            "nickname":"",
            "credito_capital":0,
            "credito_interes_compuesto":0,
            "credito_sociedad":0
        }
    );

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEmpresa({ ...empresa, [name]: value });
    };

    const handleGetEmpresa = async (id_empresa) => {
    
            setSpiner(true);
    
            try {
                await getEmpresa(id_empresa).then(response => {
                    if(response.status === 200){
                        setEmpresa(response.data.data)
                    }else{
                        alerta('No fue posible cargar la lista de empresas','','warning');
                    }
                });
    
             }catch (error) {
                alerta('Error no controlado al cargar la infromación de empresas  (root/lista/empresas) ->'+ error);
            }finally {
                setSpiner(false);
            }
        }

    const handleEditarEmpresa = async () =>{

        if(empresa.nombre.length <= 3){
            toast('Campos requeridos *','Ingrese un nombre de empresa valido');
            return
        }

        if(empresa.password.length != 0 && empresa.password.length <= 3){
            toast('Campos requeridos *','La contraseña debe tener mínimo 4 caracteres');
            return
        }

        if(empresa.segunda_clave.length != 0 && empresa.segunda_clave.length <= 3){
            toast('Campos requeridos *','La segunda clave debe tener mínimo 4 caracteres');
            return
        }
        
        Swal.fire({
                    html: `
                            <p><b>Por favor, verifique la información de la empresa.</b></p>
                            <hr></hr>
                            <div class='d-flex align-items-center justify-content-between  m-0'>
                                <h6 class="card-subtitle mb-2 text-body-secondary">Nombre de la empresa</h6>
                                <p class='m-0'><b>${empresa.nombre}</b></p>
                            </div>
                            <div class='d-flex align-items-center justify-content-between  m-0'>
                                <h6 class="card-subtitle mb-2 text-body-secondary">Usuario para inicio de sesion.</h6>
                                <p class='m-0'><b>${empresa.nickname}</b></p>
                            </div>                                           
                            <hr>
                            <p class="text-secondary" >Si está seguro, presione el botón 'Editar'. De lo contrario, presione 'Cancelar'.</p>
                    `,
                    showCancelButton: true,
                    confirmButtonColor: '#198754',
                    cancelButtonColor: '#6c757d',
                    confirmButtonText: 'Editar',
                    cancelButtonText: 'Cancelar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        handleGuardarEmpresa()
                    }
                });

    }

    const handleGuardarEmpresa = async () =>{

        setSpiner(true);
        
        await editar_empresa(empresa).then(response => {

            console.log(empresa);

            if(response.status === 200 && response.data.status_message == 'OK'){

                cerrarModal('ModalEditarEmpresa');
        
                Swal.fire({
                    title: 'Empresa editada de forma exitosa',
                    text:  'Recuerde que el usuario se puede editar desde el modulo de usuarios estando dentro de la empresa.',
                    icon: "success",
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/root';
                    }
                });

            }else{
                alerta(response.data.status_message, response.data.error_message)
            }
        }).catch(error =>{
            console.log(error)
            Swal.fire({
                title: 'Error no controlado al editar la empresa',
                text:  error.message+' ES POSIBLE QUE LA EMPRESA SE HAYA EDITADO, POR FAVOR CONFIRME EN EL MODULO DE ROOT.',
                icon: "error",
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    
                }
            });
        })

        setSpiner(false);
    }

    return(
        <React.Fragment>
            <div className="modal fade" id="ModalEditarEmpresa" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                            <h5 id="staticBackdropLabel" className='m-0'>Editar empresa</h5>
                        </div>
                        <div className="modal-body">
                            
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="nombre">Nombre empresa: <span className='text-danger'>*</span></label>
                                            <input 
                                                type="text" 
                                                id="nombre" 
                                                name="nombre" 
                                                placeholder="Nombre de la empresa" 
                                                className="form-control form-control-md" 
                                                value={empresa.nombre}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="nombre_contacto">Contacto:</label>
                                            <input 
                                                type="text" 
                                                id="nombre_contacto" 
                                                name="nombre_contacto" 
                                                placeholder="Nombre de un contacto" 
                                                className="form-control form-control-md" 
                                                value={empresa.nombre_contacto}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="telefono_contacto">Telefono:</label>
                                            <input 
                                                type="number" 
                                                id="telefono_contacto" 
                                                name="telefono_contacto" 
                                                placeholder="333 333 3333" 
                                                className="form-control form-control-md" 
                                                value={empresa.telefono_contacto}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-4'>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="correo">Correo</label>
                                            <input 
                                                type="text" 
                                                id="correo" 
                                                name="correo" 
                                                placeholder="empres@micorreo.com" 
                                                className="form-control form-control-md" 
                                                value={empresa.correo}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='col-md-5'>
                                            <label className="form-label" htmlFor="dominio">Dominio</label>
                                            <input 
                                                type="text" 
                                                id="dominio" 
                                                name="dominio" 
                                                placeholder="empres@micorreo.com" 
                                                className="form-control form-control-md" 
                                                value={empresa.dominio}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='row'>
                                        <h5>Información del usuario</h5>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="nickname">Usuario <span className='text-danger'>*</span></label>
                                            <input 
                                                type="text" 
                                                id="nickname" 
                                                name="nickname" 
                                                placeholder="pepito001" 
                                                className="form-control form-control-md" 
                                                value={empresa.nickname}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="password">Contraseña <span className='text-danger'>*</span></label>
                                            <input 
                                                type="text" 
                                                id="password" 
                                                name="password" 
                                                placeholder="*********" 
                                                className="form-control form-control-md" 
                                                value={empresa.password}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='col-md-4'>
                                            <label className="form-label" htmlFor="password_2">Segunda clave <span className='text-danger'>*</span></label>
                                            <input 
                                                type="text" 
                                                id="segunda_clave" 
                                                name="segunda_clave" 
                                                placeholder="****" 
                                                maxLength={4}
                                                className="form-control form-control-md" 
                                                value={empresa.segunda_clave}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='row'>
                                        <div className="col-md-12 mb-2">
                                            <div className="form-check form-switch">
                                                <input onChange={handleInputChange} className="form-check-input" type="checkbox" role="switch" id="credito_capital" name="credito_capital" checked={empresa.credito_capital}/>
                                                <label className="form-check-label" htmlFor="credito_capital">Creditos interes compuesto</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <div className="form-check form-switch">
                                                <input onChange={handleInputChange} className="form-check-input" type="checkbox" role="switch" id="credito_interes_compuesto" name="credito_interes_compuesto" checked={empresa.credito_interes_compuesto}/>
                                                <label className="form-check-label" htmlFor="credito_interes_compuesto">Creditos sobre capital</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            <div className="form-check form-switch">
                                                <input onChange={handleInputChange} className="form-check-input" type="checkbox" role="switch" id="credito_sociedad" name="credito_sociedad" checked={empresa.credito_sociedad}/>
                                                <label className="form-check-label" htmlFor="credito_sociedad">Creditos en sociedad</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> 
                            <button type="button" className="btn btn-success" onClick={handleEditarEmpresa}>Editar Empresa</button>                           
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        idEmpresa: state.idEmpresa
    }
}

export default connect(mapSateToProps, null)(EditarEmpresa)
