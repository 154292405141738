import React,{useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { setCredito, setSaldosEmpresa } from '../../actions'
import { solicitudes, tabla_amortizacion } from "../../services/Credito"
import { toast, alerta, formatearMonedaValue } from "../../services/utilities"
import ModalListaClientes from './ModalListaClientes';
import ModalIniciarCredito from './ModalIniciarCredito';

function Simulacion(props) {

    const location = useLocation();
    const [key, setKey] = useState(0);

    useEffect(() => {

        setKey((prevKey) => prevKey + 1);
        setTabla(false);
        setFiltro({
            "tipo_credito":"COMPUESTO",
            "valor": formatearMonedaValue(props.solicitud_credito.valor),
            "cuotas": props.solicitud_credito.cuotas,
            "interes": props.solicitud_credito.interes,
        });
        setResumen({
            "cuota": "0",
            "total_intereses": "0",
            "total_pagado": "0"
        });
        
        if(props.solicitud_credito.id_credito_solicitud > 0 ){
            handleTablaAmortizacionSolicitud();
        }
        
    }, [location]);

    const [tabla, setTabla] = useState(false);
    const [resumen, setResumen] = useState({
        "cuota": "0",
        "total_intereses": "0",
        "total_pagado": "0"
    });
    const [filtro, setFiltro] = useState({
            "tipo_credito":"COMPUESTO",
            "valor": "",
            "cuotas": "",
            "interes": ""
        }
    );

    const handleInputChange = (event) => {

        const { name, value } = event.target;
        if(name === 'valor'){
            var inputValue = value.replace(/\D/g, '');
            const formattedNumber = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            setFiltro({ ...filtro, [name]: formattedNumber });
        }else if(name === 'tipo_credito' && value === 'CAPITAL'){

            setFiltro({ ...filtro, 
                        tipo_credito: value, 
                        cuotas: 1  
                });

        }else{
            setFiltro({ ...filtro, [name]: value });
        }
    };

    const handleTablaAmortizacion = async () => {

        setTabla(false);

        if(filtro.valor.length === 0 || filtro.valor <= 0){
            toast('El valor debe ser mayor a cero','');
            return;
        }

        if(filtro.tipo_credito === 'COMPUESTO'){

            if(filtro.cuotas.length === 0){
                toast('Debe ingresar la cantidad de cuotas','');
                return;
            }

            if(filtro.cuotas > 240){
                toast('La cantidad de cuotas no puede ser mayor a 240','');
                return;
            }
        }

        if(filtro.interes.length === 0){
            toast('El % de interes no es valido','');
            return;
        }

        try {
            await tabla_amortizacion(filtro.valor, filtro.cuotas, filtro.interes, filtro.tipo_credito).then(response => {
                if(response.status === 200){
                    setTabla(response.data.data);
                    setResumen(response.data.resumen)
                }else{
                    alerta('No fue posible realizar el calculo','Por favor recargue la pagina, si el problema persiste contacte a soporte.')
                }
            });

         }catch (error) {
            
        }
    }

    const handleTablaAmortizacionSolicitud = async () => {

        setTabla(false);

        try {
            await tabla_amortizacion(props.solicitud_credito.valor, props.solicitud_credito.cuotas, props.solicitud_credito.interes, 'COMPUESTO').then(response => {
                if(response.status === 200){
                    setTabla(response.data.data);
                    setResumen(response.data.resumen)
                }else{
                    alerta('No fue posible realizar el calculo','Por favor recargue la pagina, si el problema persiste contacte a soporte.')
                }
            });

         }catch (error) {
            
        }
    }

    const handleSetiarCredito = () => {

        props.setCredito({
            "fecha_inicio" :"",
            "fecha_fin" :"",
            "observacion":"",
            "cantidad_cuotas":filtro.cuotas,
            "interes" : filtro.interes,
            "cuota_mensual": resumen.cuota,
            "pago_total_interes": resumen.total_intereses,
            "pago_total_credito": resumen.total_pagado,
            "tipo_credito": filtro.tipo_credito,
            "valor": filtro.valor,
            "saldo_actual": filtro.valor,
            "credito_nuevo":"1",
            "numero_cuota_ajuste":"",
            "cliente_id": null,
            "credito_item": tabla,
            "vehiculos": [],
            "propiedades": [],
            "letras": [],
        });
    }

    return(
        <React.Fragment>
            <div className='container-creditos'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <label className="form-label" htmlFor="tipo_credito">Tipo Credito: <span className='text-danger'>*</span></label>
                                <select className="form-select form-select-md" name="tipo_credito" value={filtro.tipo_credito} onChange={handleInputChange}>
                                    {props.usuario.credito_interes_compuesto ? <option value="COMPUESTO">INTERES COMPUESTO</option>:''}
                                    {props.usuario.credito_capital ? <option value="CAPITAL">INTERES CAPITAL</option>:''}
                                </select>
                            </div>
                            <div className='col-md-2'>
                                <label 
                                    className="form-label" 
                                    htmlFor="valor">
                                    Valor: 
                                    <span className='text-danger'>*</span>
                                </label>
                                <input 
                                    type="text" 
                                    id="valor" 
                                    name="valor" 
                                    placeholder="$0" 
                                    className="form-control form-control-md" 
                                    value={filtro.valor}onChange={handleInputChange} 
                                />
                            </div>                
                            <div className='col-md-2'>
                                <label 
                                    className="form-label" 
                                    htmlFor="cuotas">
                                    Plazo: 
                                    <span className='text-danger'>*</span>
                                </label>
                                <input 
                                    type="text" 
                                    id="cuotas"
                                    name="cuotas" 
                                    placeholder="0" 
                                    className="form-control form-control-md" 
                                    value={filtro.cuotas}onChange={handleInputChange} 
                                    disabled={filtro.tipo_credito=='CAPITAL'}
                                />
                                
                            </div>
                            
                            <div className='col-md-2'>
                                <label 
                                    className="form-label" 
                                    htmlFor="interes">
                                    % Interes: 
                                    <span className='text-danger'>*</span>
                                </label>
                                <input 
                                    type="number" 
                                    id="interes" 
                                    name="interes" 
                                    placeholder="0.0"
                                    className="form-control form-control-md" 
                                    value={filtro.interes}onChange={handleInputChange} 
                                />
                            </div>
                            <div className='col-md-2'>
                                <button 
                                    className="btn btn-success btn-lg mt-4" 
                                    type="button" 
                                    onClick={handleTablaAmortizacion}
                                >
                                    Calcular
                                </button>
                            </div>
                        </div>
                        {Array.isArray(tabla) ?                            
                            <div className="table-responsive mt-4">
                                <table className="table table-hover table-sm text-center " >
                                    <thead>
                                        <tr>
                                            <th>Numero</th>
                                            <th className='table-primary'>Cuota</th>
                                            <th className='table-success'>Abono Capital</th>
                                            <th className='table-warning'>Interes</th>
                                            <th>Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {Array.isArray(tabla) ? tabla.map((item, index) => (
                                        <tr key={index} className="text-center align-middle">
                                            <td>{item.cuota}</td>
                                            <td className='table-primary'>${item.cuota_mensual}</td>
                                            <td className='table-success'>${item.abono_capital}</td>
                                            <td className='table-warning'> ${item.intereses}</td>
                                            <td><b>${item.saldo}</b></td>
                                        </tr>
                                        )) : <tr></tr>}
                                    </tbody>
                                </table>
                            </div>                          
                        :
                            <div className='col-md-12 mt-4'>
                                <h4 className='text-body-secondary text-center mt-3'>Ingresa los campos solicitados para realizar el calculo</h4>
                            </div>
                    }
                    </div>
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-12 d-flex justify-content-center'>
                                <div className="card mb-3 mt-0">
                                    <div className="card-body">
                                        <h5 className="card-title">Resumen del credito</h5>
                                        <hr></hr>
                                        <div className='d-flex align-items-center justify-content-between  m-0'>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">Cuota Mensual</h6>
                                            <p className='m-0'>${resumen.cuota}</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between  m-0'>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">Pago en interes</h6>
                                            <p className='m-0'>${resumen.total_intereses}</p>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between m-0'>
                                            <h6 className="card-subtitle mb-2 text-body-secondary">Total Pagado</h6>
                                            <p className='m-0'>${resumen.total_pagado}</p>
                                        </div>
                                        
                                        <p className="card-text">Para continuar con el credito haga click en el boton a continuación.</p>
                                        <button 
                                            type="button" 
                                            className="btn btn-primary btn-lg" 
                                            data-bs-toggle="modal" 
                                            data-bs-target="#modalClientes"
                                            onClick={handleSetiarCredito}
                                            disabled={!Array.isArray(tabla)}
                                        >
                                            Iniciar Credito
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                  
                </div>
            </div>
            <ModalListaClientes></ModalListaClientes>
            <ModalIniciarCredito></ModalIniciarCredito>
        </React.Fragment>
    );
}

const mapSateToProps = state => {
    return {
        cliente: state.cliente,
        credito: state.credito,
        solicitud_credito: state.solicitud_credito,
        usuario:state.usuario
    }
}

const mapDispatchToProps  = {
    setSaldosEmpresa,
    setCredito
};

export default connect(mapSateToProps, mapDispatchToProps)(Simulacion)