import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { crear_usuario } from "../../services/Login"
import { toast, formatearMonedaValue, cerrarModal, alerta } from "../../services/utilities";
import Swal  from 'sweetalert2';

function CrearUsuario(props) {

    const [spiner, setSpiner] = useState(true);
    const [hayData, setHayData] = useState(false);
    const [noFoundCredito, setNoFoundCredito] = useState(false);
    const [data, setData] = useState({});
    const [form, setForm] = useState({
        "nickname" : "",
        "password" : "",
        "password_dos" : "",
        "nombre" : "",
        "email" : "",
        "celular" : "",
        "estado_id" :"1",
        "perfil_id": "3"
    });

    const handleInputChange = (event) => {
        const { name, value, type } = event.target;
        setForm({ ...form, [name]: value });
        
    };

    const handleConfirmarCrear = () => {

        if(form.nombre == '' || form.nombre.length < 4){
            toast('* Campos requeridos','Debe ingresar un nombre valido, mínimo 4 caracteres', true);
            return;
        }

        if(form.nickname == '' || form.nickname.length < 4){
            toast('* Campos requeridos','Debe ingresar un nickname valido, mínimo 4 caracteres', true);
            return;
        }

        if(form.password == '' || form.password.length < 4){
            toast('* Campos requeridos','La contraseña debe tener mínimo 4 caracteres', true);
            return;
        }

        if(form.password !== form.password_dos ){
            toast('* Campos requeridos','Las contraseñas deben coincidir.', true);
            return;
        }

        Swal.fire({
            html: `
                    <p><b>Por favor, verifique la información del usuario.</b></p>
                    <hr></hr>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Nombre del usuario</h6>
                        <p class='m-0'><b>${form.nombre}</b></p>
                    </div>
                    <div class='d-flex align-items-center justify-content-between  m-0'>
                        <h6 class="card-subtitle mb-2 text-body-secondary">Usuario para inicio de sesion.</h6>
                        <p class='m-0'><b>${form.nickname}</b></p>
                    </div>                   
                    <hr>
                    <p class="text-secondary" >Si está seguro, presione el botón 'Crear'. De lo contrario, presione 'Cancelar'.</p>
            `,
            showCancelButton: true,
            confirmButtonColor: '#198754',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Crear',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleCrearUsuario()
            }
        });

    }

    const handleCrearUsuario = async () =>{

        setSpiner(true);

        await crear_usuario(form).then(response => {

            if(response.status === 200 && response.data.status_message == 'OK'){

                cerrarModal('modalCrearUsuario');
        
                Swal.fire({
                    title: 'Usuario creado de forma exitosa ('+response.data.id_usuario+')',
                    text:  'Recuerde que el usuario se puede editar desde el modulo de usuarios.',
                    icon: "success",
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Aceptar',
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/Inicio';
                    }
                });

            }else{
                alerta(response.data.status_message, response.data.error_message)
            }
        }).catch(error =>{

            Swal.fire({
                title: 'Error no controlado al crear el usuario',
                text:  error.message+' ES POSIBLE QUE EL USUARIO SE HAYA CREADO, POR FAVOR CONFIRME EN EL MODULO DE USUARIOS.',
                icon: "error",
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = '/Inicio';
                }
            });
        })

        setSpiner(false);

    }

    return(

        <React.Fragment>
            <div className="modal fade" id="modalCrearUsuario" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-header-modal">
                        <h5 className="m-0">Crear usuario</h5>                         
                        </div>
                        <div className="modal-body">
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="nombre" name="nombre" value={form.nombre}  onChange={handleInputChange}  />
                                        <label htmlFor="nombre">Nombre complero <span className='text-danger'>*</span></label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="nickname" name="nickname" value={form.nickname}  onChange={handleInputChange} />
                                        <label htmlFor="nickname">nickname (usuario inicio sesion) <span className='text-danger'>*</span></label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="email" name="email" value={form.email}  onChange={handleInputChange}  />
                                        <label htmlFor="email">Correo</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="celular" name="celular" value={form.celular}  onChange={handleInputChange}  />
                                        <label htmlFor="celular">Telefono</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <select className="form-select" id="perfil_id" name="perfil_id"  value={form.perfil_id} onChange={handleInputChange} disabled={false}>
                                            <option value="3" >Auxiliar</option>
                                            {props.usuario.credito_sociedad ? <option value="4" >Sociedad</option>:''}
                                        </select>
                                        <label htmlFor="perfil_id">Perfil</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <select className="form-select" id="estado_id" name="estado_id"  value={form.estado_id} onChange={handleInputChange}>
                                            <option value="1">Activo</option>
                                            <option value="0">Inactivo</option>
                                        </select>
                                        <label htmlFor="perfil_id">Estado</label>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-4'>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="password" name="password" value={form.password}  onChange={handleInputChange}  />
                                        <label htmlFor="password">Contraseña</label>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-floating">
                                        <input type="text" className="form-control" id="password_dos" name="password_dos" value={form.password_dos}  onChange={handleInputChange}  />
                                        <label htmlFor="password_dos">Confirmar contraseña</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button 
                                className="btn btn-secondary btn-lg" 
                                data-bs-dismiss="modal"
                                >Cancelar
                            </button>
                            <button 
                                className="btn btn-success btn-lg"  
                                onClick={handleConfirmarCrear}                              
                                >Crear usuario
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapSateToProps = state => {

    return {
        idCredito: state.idCredito,
        usuario:state.usuario
    }
}

export default connect(mapSateToProps, null)(CrearUsuario)